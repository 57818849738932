<template>
    <div class="app-main__inner">
        <div class="main-card mb-3">
            <div class="card-body">
                <!-- <ul class="body-tabs mb-2 body-tabs-layout  nav">
                    <h3>Logs</h3>
                </ul> -->
                <div class="container">
                    <div class="main-card mb-3 card">
                        <div class="card-body">
                            <div class="flex justify-between align-items-center">
                                <h3 class="text-xl mt-3 w-full" v-if="GET_SINGLE_CONNECTION_OBJECT.input_endpoint">{{ $t('logs') }}({{ GET_SINGLE_CONNECTION_OBJECT.input_endpoint.split('/').join('') + ' - ' + GET_SINGLE_CONNECTION_OBJECT.output_endpoint.split('/').join('') | capitalizeEachString }})</h3>
                                <div class="flex justify-end w-full">
                                    <span v-if="connected" class="flex">connected <span class="h-4 w-4 block"><img src="./../../assets/images/online.png" alt=""></span></span>
                                    <span v-else title="not connected" class="h-4 w-4 block"><img src="./../../assets/images/offline.png" alt=""></span>
                                </div>
                            </div>
                            <table style="width: 100%;" id="examplemmm" class="table table-hover table-striped table-bordered mt-4">
                                <thead>
                                <tr>
                                    <!-- <th class="text-xs">{{ $t('date') }}</th> -->
                                    <!-- <th class="text-xs">{{ $t('apps') }}</th> -->
                                    <th class="text-xs">{{ $t('detail') }}</th>
                                    <!-- <th class="text-xs">{{ $t('level') }}</th> -->
                                    <!-- <th class="text-xs">{{ $t('user') }}</th> -->
                                </tr>
                                </thead>
                                <tbody v-if="socketData.length > 0">
                                    <tr v-for="(message, i) in socketData" :key="i">
                                        <td>
                                            <span v-if="message.includes('INFO')" class="text-blue-400">{{ message }}</span>
                                            <span v-else-if="message.includes('WARNING')" class="text-yellow-600">{{ message }}</span>
                                            <span v-else-if="message.includes('ERROR')" class="text-red-600">{{ message }}</span>
                                            <span v-else>{{ message }}</span>
                                        </td>
                                        <!-- <td class="text-xs font-light">{{ log.timestamp }}</td>
                                        <td class="text-xs font-light">{{ log.app_name }}</td>
                                        <td class="text-xs font-light">{{ log.error }}</td>
                                        <td class="text-xs">
                                            <span class="btn-outline-danger" v-if="log.level === 'ERROR'">{{ log.level }}</span>
                                            <span v-else>{{ log.level }}</span>
                                        </td>
                                        <td class="text-xs font-light">{{ log.username }}</td> -->
                                    </tr>
                                    <!-- <tr v-if="getSystemItems.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
                                </tbody>
                            </table>
                            <div class="float-right mb-5">
                                <!-- <paginate
                                :page-count="getSystemCount"
                                :click-handler="changePageNumberSystem"
                                :prev-text="$t('prev')"
                                :next-text="$t('next')"
                                :container-class="'pagination'">
                                </paginate> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    // import Paginate from 'vuejs-paginate'
    // import debounce from 'lodash/debounce'


    export default {
        name : 'app-logs',
        components : {
            // paginate : Paginate
        },
        data () {
            return {
                connection : null,
                connected : false,
                socketData : []
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_SINGLE_CONNECTION_OBJECT : 'customer/GET_SINGLE_CONNECTION_OBJECT',
            })
        },
        created () {
            const self = this
            const { uuid } = this.$route.params
            if (uuid) { 
                self.connection = new WebSocket(`wss://vismanet.automatiseramera.se/ws/log/${uuid}/`)
    
                self.connection.onmessage = function(event) {
                    // console.log('on message:', event.data);
                    self.socketData.unshift(event.data)
                }
    
                self.connection.onopen = function(event) {
                    // console.log('on open:', event)
                    self.connected = true
                    // console.log("Successfully connected to the echo websocket server...")
                }
            }
        },
        mounted () {
            const { uuid } = this.$route.params
            if (uuid) {
                this.getConnectionDetails(uuid)
            }
        },
        methods : {
            getConnectionDetails (data) {
                this.$store.dispatch('customer/getConnectionInformation', { id : data })
                .then(_ => {})
                .catch(err => {
                    console.log(err)
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getConnectionDetails(data)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  background-repeat: no-repeat;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 1px;
  border-radius: 0.25rem;
  padding-top: 0.3rem !important;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 0.9rem !important;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}
.sticky-table{
    position: -webkit-sticky;
    position: sticky;
    top: 61px;
    background-color: #fff;
    // background-color: #e8eaec;
    height: 50px;
    padding: 7px;
}
.card-body{
    padding: 0 0.9rem !important;
}
.app-main .app-main__inner{
    padding-top: 20px !important;
}
.body-tabs-shadow .body-tabs-animated{
    padding: unset !important;
}
.tab-btn.active{
    box-shadow: unset !important;
    border: 1px solid $theme-secondary-color !important;
    font-weight: 800;
    color: $theme-secondary-color !important;
    border-radius: 5px;
}
.tab-btn{
    color: $theme-secondary-light;
}
.remove-bg{
    background: unset !important;
    background-color: unset !important;
}
</style>