<template>
    <div class="app-container app-theme-white body-tabs-shadow">
        <div class="app-container">
            <div class="">
                <div class="d-flex">
                    <div class="text-center mt-4 float-left">
                        <img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/header_logo.png" alt="">
                    </div>
                </div>
                <div class="no-gutters row text-center mb-5">
                    <div class="col-md-12 text-center" v-if="!checking">
                        <div class="flex flex-col text-center mt-24 text-gray-500 mb-10 md:mt-20 text-4xl">
                            <span class="mx-auto mt-5">
                                <svg class="animate-spin h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </span>
                        </div>
                    </div>

                    <div class="col-md-12 text-center" v-if="checking">
                        <div class="flex flex-col text-center mt-24 text-gray-500 mb-10 md:mt-20 text-2xl">
                            {{ $t('hi') }} {{ name | capitalizeEachString }}. {{ $t('accountConfirm') }}.<br> {{ $t('redirectYou') }}<br><br>
                            <span class="mx-auto mt-5">
                                <svg class="animate-spin h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-12 text-center" v-if="expired">
                        <!-- <div class="flex flex-col text-center mt-24 text-gray-500 mb-10 md:mt-20 text-2xl">
                            <span class="mx-auto mt-5">
                                <button type="submit" class="btn rounded bg-green-500 dark:bg-gray-700 text-xl" :disabled='resending' @click="resendConfirmation">
                                    <div class="flex items-center m-auto" v-if="resending">
                                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Resending Link
                                    </div>
                                    <span class="m-auto" v-else>Resend</span>
                                </button>
                            </span>
                        </div> -->
                    </div>
                </div>
                <div class="absolute bottom-0 w-full">
                    <div class="text-center py-10">
                        <span class="text-center">Copyright © <span>{{ current }} Automatisera</span></span>
                    </div>
                </div>
            </div>
        </div>
        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name : 'login',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert')
        },
        data () {
            return {
                current : new Date().getFullYear(),
                baseURL : window.location.origin,
                name : '',
                password : '',
                confirm : '',
                checking : true,
                expired : false,
                processing : false,
                resending : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                GET_USER_UTIL : 'accountant/GET_USER_UTIL'
            })
        },
        mounted () {
            const { name } = this.$route.query
            const { error } = this.$route.query
            if (name) {
                this.name = name.split(',').map(v => typeof v !== 'string' ? v : v.trim()).join(' ').trim()
                this.checking = true
                this.confirmAccountWithToken()
            } else if (error) {
                this.checking = false
                this.expired = true
            } else {
                this.checking = false
            }
        },
        beforeMount () {
            // If user token is set, proceed to dashboard
            // if (this.USER_TOKEN) {
            //     // console.log(this.USER_TOKEN)
            //     if (this.GET_USER_UTIL === true) {
            //         window.location.href = this.baseURL + '/accountant' 
            //     } else {
            //         window.location.href = this.baseURL + '/automatisera'    
            //     }
            // }
        },
        methods : {
            confirmAccountWithToken () {
                setTimeout(() => {
                    this.$router.push({ name : 'login' })
                }, 2000)
            },
            resendConfirmation () {

            }
        }
    }
</script>

<style lang="scss" scoped>
#app{
    overflow-y: hidden !important;
}
.login-head-text{
    font-size: 1.2rem !important;
}
.login-head-text-xl{
    font-size: 1.5rem !important;
}
.override-form-field{
    border: 0;
    border-bottom: 1px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: unset !important;
    border-radius: unset !important;
}

.override-pt{
    padding-top: 1.2rem !important;
}
.override-pb{
    padding-bottom: unset !important;
}
.custom-rem{
    padding: 5rem;
}

</style>