<template>
    <div>
        <span v-if='downloading' class="flex justify-center">
            <svg class="animate-spin h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </span>
        <a v-else class="flex justify-center cursor-pointer" @click="downloadFile(data.DocumentNumber)">
            <span class="text-xl cursor-pointer icon-color iconly-brokenDownload"></span>
        </a>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import download from 'downloadjs'

    export default {
        name : 'download-manager',
        props : {
            data : {
                type : Object,
                required : true
            }
        },
        data () {
            return {
                downloading : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_COMPANY_ID : 'customer/GET_USER_COMPANY_ID',
                GET_COMPANY_BILLING : 'customer/GET_COMPANY_BILLING',
                GET_PAYMENT_TYPE : 'customer/GET_PAYMENT_TYPE',
            })
        },
        mounted () {

        },
        methods : {
            downloadFile (DocumentNumber) {
                this.downloading = true
                this.$store.dispatch('customer/downloadFile', { URL : this.$services.endpoints.GET_BILLING_HISTORY + `pdf/${DocumentNumber}/` })
                .then(res => {
                    this.downloading = false
                    const content = res.headers['content-type']
                    download(res.data, `${DocumentNumber}_invoice.pdf`, content)
                }).catch(err => {
                    this.downloading = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.location.reload()
                            })
                        }
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>