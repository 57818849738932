<template>
    <div class="app-main__inner">
        <div class="container mb-3 bg-white p-3">
            <h3 class="text-xl">{{ $t('yourAppConnection') }}</h3>
            <div class="row mt-10" v-if="GET_USER_CONNECTION.length < 1">
                <div class="col-md-4" v-for="i in 60" :key="i">
                    <p class="h-20 bg-gray-200 rounded mb-2"></p>
                    <!-- <p class="m-auto h-3  bg-gray-200 animate-pulse rounded"></p> -->
                </div>
            </div>
            <div class="row mt-10">
                <div class="col-lg-4 mb-3" v-for="connection in GET_USER_CONNECTION" :key="connection.id">
                    <div class=" teal-bg p-3 rounded-md">
                        <div class="flex justify-between align-items-center">
                            <div>
                                <div class="h-16">
                                    <img v-if="connection.app_image_url" class="h-16 border-white border-8 rounded-xl" src="https://cdn.iconscout.com/icon/free/png-256/amazon-1869030-1583154.png" alt="">
                                    <span class="text-xs" v-else>
                                        <span>{{ connection.input_app | removeUnderscoresToken }}</span><br>
                                        {{ connection.input_endpoint.split('/').join('') | capitalizeEachString }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="h-16">
                                    <img v-if="connection.app_image_url" class="h-16 border-white border-8 rounded-xl" src="https://cdn.iconscout.com/icon/free/png-256/amazon-1869030-1583154.png" alt="">
                                    <span class="text-xs" v-else>
                                        <span>{{ connection.output_app | removeUnderscoresToken }}</span><br>
                                        {{ connection.output_endpoint.split('/').join('') | capitalizeEachString }}
                                    </span>
                                </div>
                            </div>
                            <div class="flex flex-col justify-start h-16">
                                <router-link :to="{ name : 'connections', params : { id : connection.uuid } }" class="cursor-pointer"><img class="text-red-400" src="./../../assets/images/iconly/setting.svg" alt=""></router-link>
                                <div v-if="connection.definition">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <div v-if="!connection.definition">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <div @click="deleteConnection(connection.uuid)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-400 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <router-link :to="{ name : 'connectionlogs', params : { uuid : connection.uuid } }" class="cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import bus from './../../bus'

    export default {
        name : 'app-settings',
        components : {
            
        },
        data () {
            return {
                
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_CONNECTION : 'customer/GET_USER_CONNECTION',
            })
        },
        mounted () {
            this.getAllConnections()
        },
        methods : {
            getAllConnections () {
                this.$store.dispatch('customer/getUserConnections')
                .then()
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getAllConnections()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
            deleteConnection (uuid) {
                const self = this
                this.$confirm(`${this.$t('removeConnection')}?`, `${this.$t('warning')}`, {
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('cancel'),
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = 'Loading...'

                            self.$store.dispatch('customer/deleteAppConnection', uuid)
                            .then(res => {
                                bus.$emit('hide-notify-me', '')
                                this.getAllConnections()
                                this.removeConnectionStorage(uuid)
                                this.$services.helpers.notification(`${this.$t('removeSuccessful')}`, 'success', this)
                                done()
                                instance.confirmButtonLoading = false
                            }).catch((err) => {
                                bus.$emit('hide-notify-me', '')
                                done()
                                instance.confirmButtonLoading = false
                                if (err.response.status === 400) {
                                    this.$services.helpers.notification(err.response.data, 'error', this)
                                }
                            })
                        } else {
                            done()
                        }
                    }
                }).then(() => {
                    // window.Bus.$emit('get-connected-integration')
                })
                .catch(() => {
                              
                })
            },
            removeConnectionStorage (uuid) {
                const connectionUUID = window.localStorage.getItem('__ConnectionUUID__')
                if (connectionUUID && connectionUUID.includes(uuid)) {
                    // Connection in localstorgae
                    window.localStorage.removeItem('__selectApp')
                    window.localStorage.removeItem('__selectedEndpoint__')
                    window.localStorage.removeItem('__settingTab')
                    window.localStorage.removeItem('__ConnectionUUID__')
                    window.localStorage.removeItem('__ConnectionURL__')
                    window.localStorage.removeItem('__connectionQuery__')
                    window.localStorage.removeItem('__mapObject__')
                    window.localStorage.removeItem('__schemaKeys__')
                    window.localStorage.removeItem('__stateDirection__')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.teal-bg{
    background: #F4FAFC;
}
</style>
