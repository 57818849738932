<template>
    <div class="app-main__inner">
        <div class="app-page-title sticky-holder">
            <div class="page-title-wrapper">
                <div class="container">
                    <div class="row">
                        <form class="col-md-4" data-cy='filter-col'>
                            <div class="flex items-center align-items-center">
                                <div class="flex justify-between align-items-center mr-1">
                                    <i class="iconly-brokenFilter mr-1"></i>
                                   {{ $t('filterBy') }}:
                                </div>
                                <div class="form-group">
                                    <select id='filter-app-select' @change="changeAppName" type="select" :class="loading ? 'cursor-not-allowed' : null" :disabled="loading" name="customSelect" class="h-8 custom-select">
                                        <option selected disabled>{{ $t('filterApp') }}</option>
                                        <option value='all'>All</option>
                                        <option :value="acct[0]" v-for="(acct, i) in GET_ACCOUNTING_TYPE" :key="i">{{ acct[1] }}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="input-icons">
                                    <i class="iconly-brokenSearch"></i>
                                    <input data-cy='search-app' type="text" @input="searchInputTrigger" :class="loading ? 'cursor-not-allowed' : null" :disabled="loading" class="text-sm h-8 form-control custom-search" v-bind:placeholder="$t('searchApp')">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <!-- <div class="page-title-actions">
                    <form class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <input type="text" @input="searchInputTrigger" :class="loading ? 'cursor-not-allowed' : null" :disabled="loading" class="text-sm form-control custom-search" v-bind:placeholder="$t('searchApp')">
                            </div>
                        </div>
                    </form>
                </div>     -->
            </div>
        </div>
        <div class="container">
            <div class="mb-5" v-if="loading">
                <div class="row">
                    <div class="col-md-4" v-for="i in 60" :key="i">
                        <p class="h-20 bg-gray-200 rounded mb-2"></p>
                        <!-- <p class="m-auto h-3  bg-gray-200 animate-pulse rounded"></p> -->
                    </div>
                </div>
            </div>
            <!-- <div v-infinite-scroll="load" infinite-scroll-disabled="busy" infinite-scroll-distance="10" class="mt-5 mb-10 grid grid-cols-3 sm:grid-cols-5 md:grid-cols-7 lg:grid-cols-10 gap-2 infinite-list">
                <div v-for="app in GET_APP_INTEGRATION" :key="app.id">
                    <div class="w-24 h-20 card">
                        App
                    </div>
                    <div class="text-sm-app text-center">{{ $services.helpers.removeUnderscores(app.name) }}</div>
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-4 mb-2" v-for="app in GET_APP_INTEGRATION" :key="app.id">
                    <div class="flex justify-between bg-white shadow-sm rounded-lg p-1">
                        <a class="no-deco" target="_blank" :href="app.accounting[0] === 0 ? app.purchase_url : app.absolute_url">
                            <div class="flex align-items-center item-center">
                                <div class="flex rounded">
                                    <img class="h-16 w-26 rounded p-1" :src="app.image" alt="">
                                </div>
                                <div class="w-full flex content-center font-medium text-xs">
                                    {{ app.name | removeUnderscores }}
                                </div>
                            </div>
                        </a>
                        <!-- <div class="flex flex-col p-4">
                            <span class="whitespace-nowrap">{{ $t('price') }}: {{ app.price }}</span>
                            <span class="link-color">
                                <a target="_blank" class="no-de-hover hover:no-underline font-semibold" :href="app.absolute_url">{{ $t('buy') }}</a>
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="flex justify-center align-center text-center mb-5" v-if="busy">
                <svg class="animate-spin h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>

            <!-- <div class="row mb-5" v-else>
                <div class="col-md-2 mb-3" v-for="app in GET_APP_INTEGRATION" :key="app.id">
                    <div class="card mb-1 widget-chart widget-chart2 text-left card-btm-border bg-custom-theme-blue">
                        <div class="widget-chat-wrapper-outer mb-5">
                            <div class="widget-chart-content pt-3 pl-3 pb-1">
                                <h6 class="widget-subheading mb-0 opacity-5 text-dark unset-opa-increa-size">App</h6>
                            </div>
                        </div> v-for="i in 50" :key="i"
                    </div>
                    <div class="text-center">
                        <span>{{ $services.helpers.removeUnderscores(app.name) }}</span>
                    </div>
                </div>               
            </div> -->
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import debounce from 'lodash/debounce'

    export default {
        name : 'add-apps',
        data () {
            return {
                accountingType : [],
                loading : true,
                count : 60,
                busy: false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                // FILTER_ADD_APPS : 'FILTER_ADD_APPS',
                GET_ACCOUNTING_TYPE : 'customer/GET_ACCOUNTING_TYPE',
                GET_APP_INTEGRATION : 'customer/GET_APP_INTEGRATION',
                GET_APP_FILTER_STATE : 'customer/GET_APP_FILTER_STATE'
            })
        },
        mounted () {
            this.getAppList()
            // this.getAccountingPlatform()
        },
        methods : {
            searchInputTrigger : debounce(function (event) {
                if (event.target.value !== '') {
                    this.filterWebApps(event)
                } else {
                    this.$store.commit('customer/GET_ALL_APPS', null)
                }
            }, 500),
            changeAppName (_) {
                const value = document.getElementById('filter-app-select').value
                if (value === 'all') {
                    this.$store.commit('customer/GET_ALL_APPS', null)
                } else {
                    // console.log('my value:', value)
                    this.$store.commit('customer/GET_FILTER_APP_SELECT', { value })
                    // this.$store.commit('GET_FILTER_APP_SELECT', value)
                }
            },
            load () {
                this.busy = false
            },
            filterWebApps (event) {
                this.$store.commit('customer/FILTER_WEBAPPS', { value : event.target.value })
            },
            getAppList () {
                this.$store.dispatch('customer/getAppList')
                .then(_ => {
                    this.loading = false
                }).catch((err) => {
                    this.loading = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.getAppList()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            getAccountingPlatform () {
                this.$store.dispatch('customer/getAccountingPlatform')
                .then(_ => {
                    // console.log('accounting:', res)
                    // // this.loading = false
                    // if (res.status === 200) {
                    //     this.accountingType = res.data.data
                    // }
                }).catch((err) => {
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.getAccountingPlatform()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.custom-select{
    font-size: 0.71rem !important;
    // color: #EB2FAB !important;
    font-weight: 800;
}
.custom-search{
    font-size: 0.7rem !important;
}
.custom-search::placeholder{
    font-size: 0.7rem !important;
    // color: #EB2FAB !important;
    font-weight: 800;
}
.sticky-holder {
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
    z-index: 9;
    background-color: rgba(0,0,0,0.03);
    height: 75px;
}
.skeleton-box{
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
    // background-size: 800px 104px;
    height: 100px;
    width: 100%;
    border-radius: 5px;
}
.skeleton-text{
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
    // background-size: 800px 104px;
    height: 10px;
    width: 80%;
    border-radius: 5px;
    margin: 0 auto;
    padding: 5px;
}
.form-group{
    margin-bottom: unset !important;
}
.input-icons i {
    position: absolute;
    margin: 7px;
}
    
.input-icons {
    width: 100%;
    margin-bottom: 10px;
}
    
.icon {
    padding: 10px;
    min-width: 40px;
}
.custom-search{
    padding-left: 1.5rem !important;
}
.form-control{
    border: 1px solid $form-border !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
</style>