var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container body-tabs-shadow"},[_c('div',{staticClass:"container"},[_c('div',{},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"login-border-section mt-10 md:mt-5"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"mb-5",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.processRegister)}}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-base",attrs:{"for":"exampleEmail"}},[_vm._v(_vm._s(_vm.$t('username')))]),_c('div',[_c('ValidationProvider',{attrs:{"name":"username","rules":"required|minmax:4,15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control font-light override-form-field-l",attrs:{"name":"username","type":"text","required":""},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('small',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-base",attrs:{"for":"exampleEmail"}},[_vm._v(_vm._s(_vm.$t('companyName')))]),_c('div',[_c('ValidationProvider',{attrs:{"name":"companyName","rules":"required|minmax:4,15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company_name),expression:"company_name"}],staticClass:"form-control font-light override-form-field-l",attrs:{"name":"company","type":"text","required":""},domProps:{"value":(_vm.company_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company_name=$event.target.value}}}),_c('small',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-base",attrs:{"for":"exampleEmail"}},[_vm._v(_vm._s(_vm.$t('companyNumber')))]),_c('div',[_c('ValidationProvider',{attrs:{"name":"companyNumber","rules":"required|validcom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company_number),expression:"company_number"}],staticClass:"form-control font-light override-form-field-l",attrs:{"type":"text","required":""},domProps:{"value":(_vm.company_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company_number=$event.target.value}}}),_c('small',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-base",attrs:{"for":"exampleEmail"}},[_vm._v(_vm._s(_vm.$t('firstName')))]),_c('div',[_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstname),expression:"firstname"}],staticClass:"form-control font-light override-form-field-l",attrs:{"name":"firstname","type":"text","required":""},domProps:{"value":(_vm.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstname=$event.target.value}}}),_c('small',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-base",attrs:{"for":"exampleEmail"}},[_vm._v(_vm._s(_vm.$t('lastName')))]),_c('div',[_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastname),expression:"lastname"}],staticClass:"form-control font-light override-form-field-l",attrs:{"name":"lastname","type":"text","required":""},domProps:{"value":(_vm.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastname=$event.target.value}}}),_c('small',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-base",attrs:{"for":"exampleEmail"}},[_vm._v(_vm._s(_vm.$t('email')))]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control font-light override-form-field-l",attrs:{"name":"email","type":"email","required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('small',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-base",attrs:{"for":"exampleEmail"}},[_vm._v(_vm._s(_vm.$t('password')))]),_c('div',[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|minmax:7,15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control font-light override-form-field-l",attrs:{"name":"password","type":"password","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('small',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mt-4"},[_c('button',{staticClass:"w-full font-semibold shadow btn btn-custom btn-primary btn-lg btn-wide round-btn custom-btn-color",attrs:{"type":"submit","disabled":invalid || _vm.processing}},[(_vm.processing)?_c('span',[_vm._v(_vm._s(_vm.$t('creatingAccount')))]):_c('span',[_vm._v(_vm._s(_vm.$t('signup')))])])])]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"font-md-login"},[_vm._v(_vm._s(_vm.$t('already'))+"? "),_c('router-link',{staticClass:"no-deco link-color",attrs:{"to":{name : 'login'}}},[_vm._v(_vm._s(_vm.$t('login')))])],1)])])])]}}])})],1)])]),_c('div',{staticClass:"container font-light text-xs text-center md:bottom-0 md:abslute mb-4"},[_c('span',[_vm._v("Copyright © "),_c('span',[_vm._v(_vm._s(_vm.current)+" Automatisera Mera")])]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('switch'))+" "),(_vm.GET_LOCALE_LANG === 'se')?_c('a',{staticClass:"font-bold no-deco no-de-hover link-color",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.changeLocale('en')}}},[_vm._v(_vm._s(_vm.$t('english')))]):_c('a',{staticClass:"font-bold no-deco no-de-hover link-color",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.changeLocale('se')}}},[_vm._v("Swedish")])])])])]),_c('Notification')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-center mt-4 float-left"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"md:ml-32  md:w-1/2"})])}]

export { render, staticRenderFns }