<template>
    <div class="app-main__inner">
        <div class="container">
            <div class="row">
                <div class="col-md-4 mb-4">
                    <form class="mb-3" @submit.prevent="noSubmitFaq">
                        <div class="form-group">
                            <div class="input-icons">
                                <i class="iconly-brokenSearch"></i>
                                <input type="text" @input="searchInputTrigger" class="text-xs form-control custom-search" v-bind:placeholder="$t('searchCategory')">
                            </div>
                        </div>
                    </form>
                    <div class="custom-card-section md:h-screen p-4 sticky-holder">
                        <span class="text-xs link-theme-color border-theme-color border-b-1">{{ $t('FAQSmall') }}</span><br>
                        <div v-if="loadingCategory">
                            <p class="h-3 w-40 bg-gray-200 animate-pulse rounded mb-2 text-xs" v-for="(i, d) in 5" :key="d"></p>
                        </div>
                        <div v-else>
                            <MenuFAQ v-for="(category, i) in GET_FAQ_CATEGORY" :key="i" :data="category" />
                        </div>
                        <div v-if="GET_FAQ_CATEGORY.length < 1 && !loadingCategory">
                            <p class="text-gray-400 text-xs"><i>{{ $t('noCate') }}</i></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div v-if="loadingFaq">
                        <p class="h-10 w-full bg-gray-200 animate-pulse rounded mb-2 text-xs" v-for="(i, d) in 9" :key="d"></p>
                    </div>
                    <div>
                        <AccordionFAQ v-for="(faq, i) in GET_FAQ_DATA" :key="i" :data="faq"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import debounce from 'lodash/debounce'

    import i18n from '@/plugins/i18n'
    import AccordionFAQ from './../../customComponent/AccordionFAQ'
    import MenuFAQ from './../../customComponent/MenuFAQ'

    export default {
        name : 'faq-section',
        components : {
            AccordionFAQ,
            MenuFAQ
        },
        data () {
            return {
                loadingCategory : true,
                loadingFaq : true,
                searchLang : i18n
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_FAQ_DATA : 'customer/GET_FAQ_DATA',
                GET_FAQ_CATEGORY : 'customer/GET_FAQ_CATEGORY',
            })
        },
        mounted () {
            // if (this.GET_FAQ_DATA.length > 0 && this.GET_FAQ_CATEGORY.length > 0) {
            //     this.loadingCategory = false
            //     this.loadingFaq = false
            // } else {
            //     this.loadingFaq = true
            //     this.loadingCategory = true
            // }
            this.getFaqList()
        },
        methods : {
            noSubmitFaq () {
                return false
            },
            searchInputTrigger : debounce(function (event) {
                if (event.target.value !== '') {
                    this.filterCategory(event)
                } else {
                    this.$store.commit('customer/CATEGORY_FAQ_ALL', null)
                }
            }, 500),
            filterCategory (event) {
                this.$store.commit('customer/FILTER_CATEGORY', { value : event.target.value })
            },
            getFaqList () {
                this.$store.dispatch('customer/getFaqList')
                .then(_ => {
                    this.loadingFaq = false
                    this.getFaqCategoryList()
                }).catch((err) => {
                    this.loadingFaq = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getFaqList()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
            getFaqCategoryList () {
                this.$store.dispatch('customer/getFaqCategoryList')
                .then(_ => {
                    this.loadingCategory = false
                }).catch((err) => {
                    this.loadingCategory = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getFaqCategoryList()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.remove-border{
    border: unset !important;
}
.body-faq-text{
    font-size: 1.3rem;
}
.blue-heading-faq{
    color: #0056b3 !important;
}
.custom-card-section{
    box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03),0 0.9375rem 1.40625rem rgba(4,9,20,0.03),0 0.25rem 0.53125rem rgba(4,9,20,0.05),0 0.125rem 0.1875rem rgba(4,9,20,0.03);
    border-width: 0;
    transition: all .2s;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(26,54,126,0.125);
}
.font-size-xl{
    font-size: 1.4rem;
}
.sticky-holder {
    position: -webkit-sticky;
    position: sticky;
    top: 47px;
}
.border-b-1{
    border-bottom-style: solid;
    border-bottom-width: thin;
}
.skeleton-text{
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
    // background-size: 800px 104px;
    height: 15px;
    width: 50%;
    border-radius: 5px;
}
.skeleton-faq{
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
    // background-size: 800px 104px;
    height: 50px;
    width: 100%;
    border-radius: 5px;
}
.border-theme-color{
    border-color: $theme-primary-color !important;
}
.form-control{
    border: 1px solid $form-border !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.input-icons i {
    position: absolute;
    margin: 11px;
}
    
.input-icons {
    width: 100%;
    margin-bottom: 10px;
}
    
.icon {
    padding: 10px;
    min-width: 40px;
}
.custom-search{
    padding-left: 1.8rem !important;
}
</style>