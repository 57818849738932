<template>
    <div>
        <div class="cursor p-1" @click="openAccordion">            
            <div class="text-xs font-bold flex"> 
                <span class="arrow-theme">
                    <svg class="h-3 w-3 arrow-theme" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                </span> {{ data.category }}</div>
        </div>
        <transition name="slide">
            <div v-if="active">
                <div class="ml-3 text-xs font-light">
                    <div v-for="question in data.subLink" :key="question.id">
                        <el-tooltip effect="light" :content="question.question" placement="top">
                            <p @click="$store.commit('customer/SET_FAQ_LIST', question)" class="cursor-pointer question-hover">{{ question.question }}</p>
                        </el-tooltip>
                    </div>
                    {{ data.description }}
                </div>
            </div>  
        </transition>
    </div>
</template>

<script>
    export default {
        name : 'accordion-faq',
        props: {
            data : {
                type : Object,
                required : false
            }
        },
        data () {
            return {
                active : false,
                // baseURL : window.location.origin,
            }
        },
        methods : {
            openAccordion () {
                this.active = !this.active
            },
            
        }
    }
</script>

<style lang="scss" scoped>
@import "./../assets/variables.scss";
.cc-color{
    color: #736f6f !important;
}
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
.question-hover:hover{
    color: #1E2C41;
}
.arrow-theme{
    color: $theme-primary-color !important;
}
</style>