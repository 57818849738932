<template>
    <div class="app-main__inner">
        <div class="container">
            <div class="row mb-3">
                <div class="col-md-4 col-xl-4 mb-3">
                    <div class="card p-3 bg-theme-pink rounded-lg r-min-h">
                        <div class="text-center text-color-theme font-semibold text-sm whitespace-nowrap">{{ $t('bookKept') }}</div>
                        <div class="text-center mt-3">
                            <span class="text-2xl text-color-theme font-semibold" data-cy='total-transac' id="total-transac">{{ $services.helpers.animateValue('total-transac', 0, GET_DASHBOARD_METRICS.total_transactions_book_kept || 0, 2000) }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xl-4 mb-3">
                    <div class="card p-3 bg-theme-blue rounded-lg r-min-h">
                        <div class="text-center text-secondary font-semibold text-sm break-word">{{ $t('totalSaving') }}</div>
                        <div class="text-center mt-3">
                            <span class="text-2xl text-secondary font-semibold" data-cy='total-saving-acc-cst' id="total-saving-acc-cst">{{ $services.helpers.animateValue('total-saving-acc-cst', 0, GET_DASHBOARD_METRICS.total_accounting_savings || 0, 4000) }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xl-4 mb-3">
                    <div class="card p-3 bg-theme-green rounded-lg r-min-h">
                        <div class="text-center text-tertiary font-semibold text-sm whitespace-nowrap">{{ $t('subscription') }}</div>
                        <div class="text-center mt-3">
                            <span class="text-2xl text-tertiary font-semibold" data-cy='total-sub' id="total-sub">{{ $services.helpers.animateValue('total-sub', 0, GET_DASHBOARD_METRICS.total_subscriptions || 0, 1000) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5 mb-3">
                <h2 class="text-gray-900 text-base font-semibold">{{ $t('transactionHistory') }}</h2>
            </div>
            <div class="row " v-if="loadingSub">
                <div class="col-md-4 col-xl-4 mb-2" v-for="i in 5" :key="i">
                    <p class="h-32 w-full animate-pulse bg-gray-200 rounded mb-2"></p>
                </div>
            </div>

            <div id="row-test-graph" class="row" v-else>
                <div class="col-md-4 col-xl-4" v-for="(sub, i) in DashboardSub" :key="i">
                    <div class="mb-3 card bg-custom-theme-gray">
                        <div class="card-header-tab card-header bg-custom-theme-gray">
                            <!-- <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                            </div>
                            <div class="btn-actions-pane-right text-capitalize actions-icon-btn">
                                <div class="btn-group dropdown">
                                    <button type="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false" class="btn-icon btn-icon-only btn btn-link">
                                        <i class="pe-7s-menu btn-icon-wrapper"></i>
                                    </button>
                                </div>
                            </div> -->
                        </div>
                        <div class="p-0 card-body">
                            <div class="widget-chat-wrapper-outer mb-2">
                                <div class="widget-chart-content pt-3 pb-1">
                                    <!-- <h6 class="widget-subheading mb-0 opacity-5 text-dark font-semibold mb-2 unset-opa-increa-size card-text text-xs">{{ sub.options.title.text }} Transaction History</h6> -->
                                    <div class="widget-chart-flex">
                                        <div class="widget-numbers p-2">
                                            <apexchart ref="chart" type="area" height="200" :options="sub.options" :series="sub.options.series"></apexchart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    // import groupBy from 'lodash/groupBy'

    export default {
        name : 'dashboard-single',
        data () {
            return {
                DashboardSub : [],
                dashboardMetrics : {
                    total_accounting_savings : 0,
                    total_subscriptions : 0,
                    total_transactions_book_kept : 0
                },
                loadingSub : true,
                totalTransaction : 0,
                totalSaving : 0,
                serverLineSeries: [
                    {
                        name: 'Website Blog',
                        type: 'area',
                        data: [44, 5, 4, 61, 22, 13, 20, 35, 52, 10, 27, 1]
                    },
                    {
                        name: 'Social Media',
                        type: 'area',
                        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
                    }
                ],
                chartAreaOptions: {
                    chart: {
                        toolbar : {
                            show : false
                        },
                        height: 350,
                        type: 'line',
                    },
                    stroke: {
                        width: [1, 1],
                        curve: 'straight',
                    },
                    fill: {
                        type: 'solid'
                    },
                    title: {
                        text: 'Traffic Sources'
                    },
                    colors: ['#FFC917', '#1355AF', '#7E3469', '#EE4D81'],
                    dataLabels: {
                        enabled: false,
                    },
                    legend : {
                        show : false
                    },
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    xaxis: {
                        // type: 'datetime'
                    },
                    yaxis: [
                        {
                            title: {
                                text: '',
                            },
                            // tickAmount: 0,
                            labels: {
                                formatter: function (val) {
                                    return val.toFixed(0)
                                },
                            },
                        }
                    ],
                },
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_DASHBOARD_METRICS : 'customer/GET_DASHBOARD_METRICS',
                GET_USER_COMPANY_ID : 'customer/GET_USER_COMPANY_ID'
            })
        },
        mounted () {
            // if (this.GET_USER_COMPANY_ID) {
            //     this.getDashboardMetrics(this.GET_USER_COMPANY_ID)
            //     this.getUserTransaction(this.GET_USER_COMPANY_ID)
            // } else {
            //     this.getUserTransaction()
            //     this.getDashboardMetrics()
            // }
        },
        beforeDestroy () {
            // console.log(this.$refs.chart.destroy())
        },
        methods : {
            getDashboardMetrics (userID) {
                const URL = userID ? this.$services.endpoints.GET_COMPANY_METRICS + `${userID}/` : this.$services.endpoints.GET_COMPANY_METRICS
                // this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
                // this.$services.axios.get(URL)
                this.$store.dispatch('customer/getDashboardMetrics', URL)
                // .then(_ => {
                //     // console.log(URL)
                //     // console.log('Metrics:', res)
                //     // this.loadingSub = false
                //     // if (res.data.success) {
                //     //     this.dashboardMetrics = res.data.data
                //     // }
                // }).catch((_) => {
                //     // this.loadingSub = false
                //     // console.log(err)
                //     // this.processing = false
                // })
            },
            getUserTransaction (userID) {
                const URL = userID ? this.$services.endpoints.GET_USER_TRANSACTION + `${userID}/` : this.$services.endpoints.GET_USER_TRANSACTION
                // this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
                // this.$services.axios.get(URL)
                this.$store.dispatch('customer/getUserTransaction', URL)
                .then(res => {
                    // console.log('Tranasac:', res)
                    this.loadingSub = false
                    this.totalTransaction = res.data.data.length
                    if (res.data.data.length > 0) {
                        res.data.data.forEach(element => {
                            if (Object.values(element)[0].length > 0) {
                                // console.log(Object.values(element)[0])
                                const obj = {}
                                obj.options = JSON.parse(JSON.stringify(this.chartAreaOptions))
                                obj.options.series = []
    
                                obj.options.title.text = Object.keys(element)[0]
                                obj.options.series = Object.values(element)[0]
                                this.DashboardSub.push(obj)
                            }
                        })
                    }
                    // if (res.data.success) {
                    // }
                }).catch((err) => {
                    this.loadingSub = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                // this.getSubscriptionList()
                            })
                            .catch(_ => {
                                // console.log('error response')
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                    // console.log(err)
                    // this.processing = false
                })
            },
            groupBy (array, key) {
                // Return the reduced array
                return array.reduce((result, currentItem) => {
                // If an array already present for key, push it to the array. Otherwise create an array and push the object.
                (result[currentItem[key]] = result[currentItem[key]] || []).push(currentItem)
                // return the current iteration `result` value, this will be the next iteration's `result` value and accumulate
                return result
                }, {}) // Empty object is the initial value for result object
            },
        },
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.bg-custom-theme-blue{
    background: #8AACDE !important;
}
.bg-theme-pink{
    background: #FEF1F5;
    border: 0.5px solid $theme-primary-color;
}
.bg-theme-blue{
    background: #F2F0FF;
    border: 0.5px solid #583EF9;
}
.bg-theme-green{
    background: #F2FDF5;
    border: 0.5px solid $theme-tertiary-color;
}
.text-tertiary {
    color: $theme-tertiary-color !important;
}
.text-secondary{
    color: $theme-secondary-color !important;
}
.text-color-theme{
    color: $theme-primary-color !important;
}
.bg-custom-theme-teal{
    background: #85CED2 !important;
}
.bg-custom-theme-green{
    background: #BBE5AC !important;
}
.bg-custom-theme-gray{
    background-color: #fff !important;
}
.unset-opa-increa-size{
    opacity: unset !important;
    font-size: 1.3rem;
}
.card-text-num{
    color: #7E3469 !important;
    font-weight: 800;
    float: right;
}
.card-header{
    height: unset !important;
}
</style>