<template>
    <div class="app-container body-tabs-shadow"> <!--app-theme-white-->
        <div class="container">
            <div class="">
                <div class="d-flex">
                    <div class="text-center mt-4 float-left">
                        <!-- <img class="img-fluid h-12 logo-top pl-5" src="./../../../assets/images/header_logo.png" alt=""> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="md:ml-32 w-7/12">
                            <!-- <img class="img-fluid d-none d-lg-block" src="./../../../assets/images/illus/forget.svg" alt="authentication"> -->
                            <!-- <h3 class="text-gray-800 whitespace-nowrap mt-10 md:-mt-10 font-semibold text-2xl">{{ $t('forgetPassword') }}</h3> -->
                        </div>
                    </div>
                    <div class="col-md-4 md:mt-10">
                        <div class="login-border-section md:mt-20">
                            <ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
                            <form class="mt-5 mb-5" @submit.prevent="handleSubmit(forget)">
                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base">{{ $t('emailAddress') }}</label>
                                    <div>
                                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                                            <input v-model="email" name="email" type="email" class="form-control font-light override-form-field-l" required>
                                            <small class="text-red-500">{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base"></label>
                                    <div>
                                        <button class="w-full shadow btn btn-custom btn-primary btn-lg btn-wide round-btn custom-btn-color" type="submit" :disabled="invalid || processing">
                                            <span v-if="processing">{{ $t('sendLink')}} </span>
                                            <span v-else>{{ $t('continue') }}</span>                        
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base"></label>
                                    <div>
                                        <span class="font-md-login">{{ $t('already') }}? <router-link id="login-url" :to="{name : 'login'}" class="no-deco no-de-hover link-color">{{ $t('login') }}</router-link></span><br><br>
                                        <span class="font-md-login">{{ $t('notCustomer') }}? <router-link :to="{name : 'register'}" class="no-deco no-de-hover link-color">{{ $t('welcome') }}</router-link></span>
                                    </div>
                                </div>
                                <div class="form-group mt-10">
                                    <div>
                                        <span class="font-md-login text-base paragraph-color">{{ $t('golf') }} 
                                            <a target="_blank" href="https://golf.automatiseramera.se/login/" class="font-semibold text-base hover:no-underline text-blue-700">
                                                {{ $t('login') }}
                                            </a>
                                        </span><br><br>
                                    </div>
                                </div>
                            </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
                <div class="font-light text-xs text-center bottom-0 absolute md:relative mb-4">
                    <span>Copyright © <span>{{ current }} Automatisera Mera</span></span>
                    <span> {{ $t('switch') }} 
                        <a @click="changeLocale('en')" href="javascript:void(0);" class="font-bold no-deco no-de-hover link-color" v-if="GET_LOCALE_LANG === 'se'">{{ $t('english') }}</a>
                        <a @click="changeLocale('se')" href="javascript:void(0);" class="font-bold no-deco no-de-hover link-color" v-else>Swedish</a>
                    </span>
                </div>
            </div>
        </div>
        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { ValidationProvider, ValidationObserver, localeChanged } from 'vee-validate'
    import i18n from '@/plugins/i18n'

    export default {
        name : 'forget',
        components : {
            Notification : () => import('./../../../customComponent/NotificationAlert'),
            ValidationProvider,
            ValidationObserver
        },
        data () {
            return {
                current : new Date().getFullYear(),
                baseURL : window.location.origin,
                loaded : false,
                email : '',
                processing : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                GET_USER_UTIL : 'customer/GET_USER_UTIL',
                GET_LOCALE_LANG : 'utility/GET_LOCALE_LANG'
            })
        },
        beforeMount () {
            // If user token is set, proceed to dashboard
            if (this.USER_TOKEN) {
                // console.log(this.USER_TOKEN)
                if (this.GET_USER_UTIL === true) {
                    this.$router.replace({ name : 'accountantdash' })
                    // window.location.href = this.baseURL + '/accountant' 
                } else {
                    this.$router.replace({ name : 'dashboard' })
                    // window.location.href = this.baseURL + '/automatisera'    
                }
            }
        },
        methods : {
            changeLocale (locale) {
                i18n.locale = locale
                this.$store.commit('utility/CHANGE_LOCALE', locale)
                localeChanged()
            },
            forget () {
                if (this.email === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else if (!this.$services.helpers.validateEmailAddress(this.email)) {
                    this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
                } else {
                    this.processing = true
                    this.$store.dispatch('auth/forgetPassword', { email : this.email })
                    .then(res => {
                        this.processing = false
                        this.email = ''
                        this.$services.helpers.notification(res.data.data, 'success', this)
                    }).catch((err) => {
                        this.processing = false
                        if (err.response.status === 400) {
                            this.$services.helpers.notification(err.response.data.data, 'error', this)
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";
#app{
    overflow-y: hidden !important;
}
.login-head-text{
    font-size: 1.2rem !important;
}
.login-head-text-xl{
    font-size: 1.5rem !important;
}
.override-form-field{
    border: 0;
    border-bottom: 1px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: unset !important;
    border-radius: unset !important;
}

.override-pt{
    padding-top: 1.2rem !important;
}
.override-pb{
    padding-bottom: unset !important;
}
.custom-rem{
    padding: 5rem;
}
.form-control{
    border: 1px solid $form-border !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}

</style>