<template>
    <div class="app-container app-theme-white body-tabs-shadow">
        <div class="app-container">
            <div class="">
                <div class="d-flex">
                    <div class="text-center mt-4 float-left">
                        <img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/header_logo.png" alt="">
                    </div>
                </div>
                <div class="no-gutters row text-center mb-5" v-if="show">
                    <div class="col-md-12 text-center">
                        <div class="text-center mt-24 text-gray-700 mb-10 dark:text-gray-400 md:mt-10 text-4xl">
                            {{ $t('hi') }} {{ $route.query.name }}, {{ $t('almostDone') }}
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-4 text-center">
                        <div class="border bg-gray-100 rounded px-12 py-2">
                            <form class="mt-10" @submit.prevent="processInvite">
                                <div class="flex flex-col mb-8">
                                    <label class="mb-2 text-sm text-gray-700 dark:text-gray-400 dark:text-gray-400" for="email">{{ $t('password') }}</label>
                                    <input type="password" v-model="password" class="w-full form-control" required>
                                    <!-- <small class="text-red-400 text-sm" v-if="error.email">{{ error.email || null }}</small> -->
                                </div>

                                <div class="flex flex-col mb-8">
                                    <label class="mb-2 text-sm text-gray-700 dark:text-gray-400 dark:text-gray-400" for="email">{{ $t('confirmPassword') }}</label>
                                    <input type="password" v-model="confirm" class="w-full form-control" required>
                                    <!-- <small class="text-red-400 text-sm" v-if="error.email">{{ error.email || null }}</small> -->
                                </div>

                                <div class="mt-10 flex flex-col space-y-5 mb-5">
                                    <button type="submit" :disabled="processing" class="flex btn-custom transition m-auto duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100 btn btn-primary w-full p-2 mb-2 font-semibold dark:text-gray-400 text-xl">
                                        <div class="flex items-center m-auto" v-if="processing">
                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            {{ $t('creatingAccount') }}
                                        </div>
                                        <span class="m-auto" v-else>{{ $t('setUpAccount') }}</span>
                                    </button>
                                    <!-- <p class="text-center text-gray-700 text-sm dark:text-gray-400">Don't have an account? <router-link :to="{ name : 'register' }" class="text-xl hover:text-blue-500">Sign Up</router-link></p> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                </div>
                <div class="container font-light text-xs text-center bottom-0 absolute mb-4">
                    <span>Copyright © <span>{{ current }} Automatisera Mera</span></span>
                    <span> {{ $t('switch') }} 
                        <a @click="changeLocale('en')" href="javascript:void(0);" class="font-bold no-deco no-de-hover link-color" v-if="GET_LOCALE_LANG === 'se'">{{ $t('english') }}</a>
                        <a @click="changeLocale('se')" href="javascript:void(0);" class="font-bold no-deco no-de-hover link-color" v-else>Swedish</a>
                    </span>
                </div>
            </div>
        </div>
        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import i18n from '@/plugins/i18n'

    export default {
        name : 'login',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert')
        },
        data () {
            return {
                current : new Date().getFullYear(),
                baseURL : window.location.origin,
                name : '',
                inviteLink : '',
                password : '',
                confirm : '',
                show : false,
                processing : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                GET_USER_UTIL : 'accountant/GET_USER_UTIL',
                GET_LOCALE_LANG : 'utility/GET_LOCALE_LANG'
            })
        },
        mounted () {
            const { name } = this.$route.query
            const inviteCode = this.$route.query.invite_code
            if (inviteCode && name) {
                this.inviteLink = inviteCode
                this.show = true
            } else {
                console.log(name, inviteCode)
                this.show = false
            }
        },
        beforeMount () {
            // If user token is set, proceed to dashboard
            if (this.USER_TOKEN) {
                // console.log(this.USER_TOKEN)
                if (this.GET_USER_UTIL === true) {
                    this.$router.replace({ name : 'accountantdash' })
                    // window.location.href = this.baseURL + '/accountant' 
                } else {
                    this.$router.replace({ name : 'dashboard' })
                    // window.location.href = this.baseURL + '/automatisera'    
                }
            }
        },
        methods : {
            processInvite () {
                if (this.password === '' || this.confirm === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else if (this.password.length < 6) {
                    this.$services.helpers.notification(this.$t('passwordGreater'), 'error', this)
                } else if (this.password !== this.confirm) {
                    this.$services.helpers.notification(this.$t('passwordMatch'), 'error', this)
                } else {
                    this.processing = true
                    // this.$services.axios.post(this.$services.endpoints.INVITE_SET_PASSWORD, {
                    //     invitation_code : this.inviteLink,
                    //     password : this.password,
                    //     confirm_password : this.confirm,
                    // })
                    const payload = {
                        invitation_code : this.inviteLink,
                        password : this.password,
                        confirm_password : this.confirm,
                    }
                    this.$store.dispatch('auth/processInvite', payload)
                    .then(_ => {
                        this.processing = false
                        this.$services.helpers.notification(this.$t('loginSuccess'), 'success', this)
                        setTimeout(() => {
                            // window.location.href = '/'
                            this.$router.push({ name : 'login' })
                        }, 1000)
                        // if (res.data.success === true) {
                        // }
                    }).catch(err => {
                        this.processing = false
                        if (err.response.status === 400) {
                            this.$services.helpers.notification(err.response.data.data, 'error', this)
                        }
                    })
                }
            },
            changeLocale (locale) {
                i18n.locale = locale
                this.$store.commit('utility/CHANGE_LOCALE', locale)
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
#app{
    overflow-y: hidden !important;
}
.login-head-text{
    font-size: 1.2rem !important;
}
.login-head-text-xl{
    font-size: 1.5rem !important;
}
.override-form-field{
    border: 0;
    border-bottom: 1px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: unset !important;
    border-radius: unset !important;
}

.override-pt{
    padding-top: 1.2rem !important;
}
.override-pb{
    padding-bottom: unset !important;
}
.custom-rem{
    padding: 5rem;
}
.form-control{
    border: 1px solid $form-border !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
</style>