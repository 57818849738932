<template>
    <div class="app-main__inner">
        <div class="main-card mb-3">
            <div class="card-body">

                <div class="tab-content">
                    <div class="tab-pane tabs-animation fade active show" id="tab-content-0" role="tabpanel">
                        <div class="container">
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <div class="row sticky-table mb-3 mt-3">
                                        <!-- <div class="col-md-4">
                                            <span>
                                                <el-date-picker
                                                class="w-full text-xs font-light app-input"
                                                v-model="value1"
                                                type="daterange"
                                                @change='dateFilter'
                                                value-format="yyyy-MM-dd"
                                                format="yyyy/MM/dd"
                                                start-placeholder="Start datum"
                                                end-placeholder="Slutet datum">
                                                </el-date-picker>
                                            </span>
                                        </div> -->
                                        <div class="col-md-4">
                                            <div class="flex md:justify-center">
                                                <div class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">
                                                    {{ $t('level') }}: 
                                                </div>
                                                <div class="form-group">
                                                    <select id="app-name-id" @change="changeAppName" class="text-xs form-select w-full">
                                                        <option value="all" >{{ $t('all') }}</option>
                                                        <option value="ERROR" >{{ $t('error') }}</option>
                                                        <option value="INFO" >{{ $t('info') }}</option>
                                                        <option value="WARNING" >{{ $t('warning') }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4">
                                            <div class="flex">
                                                <div class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">
                                                    <i class="iconly-brokenFilter mr-1"></i>
                                                    {{ $t('filterBy') }}:
                                                </div>
                                                <div class="form-group">
                                                    <select class="text-xs form-select w-full removs-bg">
                                                        <option selected disabled>{{ $t('filterUser') }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <table style="width: 100%;" id="examplemmm" class="table table-hover table-striped table-bordered">
                                        <thead>
                                        <tr>
                                            <th class="text-xs">{{ $t('date') }}</th>
                                            <th class="text-xs">{{ $t('level') }}</th>
                                            <th class="text-xs">{{ $t('detail') }}</th>
                                            <th class="text-xs">{{ $t('function') }}</th>
                                            <th class="text-xs">{{ $t('file') }}</th>
                                            <!-- <th class="text-xs">{{ $t('user') }}</th> -->
                                        </tr>
                                        </thead>
                                        <tbody v-loading='vloading'>
                                            <tr v-for="(log, i) in GET_APP_LOG" :key="i">
                                                <td class="text-xs font-light">{{ log.datetime }}</td>
                                                <td class="text-xs font-light">
                                                    <span v-if="log.level === 'INFO'" class="text-blue-400 font-semibold">{{ $t('info') }}</span>
                                                    <span v-if="log.level === 'WARNING'" class="text-yellow-600 font-semibold">{{ $t('warning') }}</span>
                                                    <span v-if="log.level === 'ERROR'" class="text-red-600 font-semibold">{{ $t('error') }}</span>
                                                </td>
                                                <td class="text-xs font-light">{{ log.message }}</td>
                                                <td class="text-xs font-light">{{ log.function }}</td>
                                                <td class="text-xs font-light">{{ log.filename }}</td>
                                                <!-- <td class="text-xs">
                                                    <span class="btn-outline-danger" v-if="log.level === 'ERROR'">{{ log.level }}</span>
                                                    <span v-else>{{ log.level }}</span>
                                                </td>
                                                <td class="text-xs font-light">{{ log.username }}</td> -->
                                            </tr>
                                            <!-- <tr v-if="getSystemItems.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
                                        </tbody>
                                    </table>
                                    <div class="float-right mb-5">
                                        <!-- <paginate
                                        :page-count="getSystemCount"
                                        :click-handler="changePageNumberSystem"
                                        :prev-text="$t('prev')"
                                        :next-text="$t('next')"
                                        :container-class="'pagination'">
                                        </paginate> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Paginate from 'vuejs-paginate'
    import debounce from 'lodash/debounce'


    export default {
        name : 'app-logs',
        components : {
            paginate : Paginate
        },
        data () {
            return {
                AppLogs : [],
                copyAppLog : [],
                sytemAppLogs : [],
                copySystemLogs : [],
                value1 : '',
                value2 : '',
                app_type : [],
                parPage: 10,
                currentPage: 1,
                loading : false,
                currentSystemPage : 1,
                vloading : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_APP_LOG : 'customer/GET_APP_LOG'
            })
        },
        mounted () {
            this.getAppLogs()
        },
        methods : {
            searchInputTrigger : debounce(function (event) {
                if (event.target.value !== '') {
                    this.filterUsers(event)
                } else {
                    this.$store.commit('customer/DEFAULT_APP_LOGS')
                }
            }, 500),
            filterUsers (event) {
                this.$store.commit('customer/FILTER_USER_LOGS', event)
            },
            changePageNumberSystem (pageNum) {
                this.currentSystemPage = Number(pageNum)
            },
            changePageNumber (pageNum) {
                this.currentPage = Number(pageNum)
            },
            changeAppName (_) {
                const value = document.getElementById('app-name-id').value
                if (value === 'all') {
                    this.$store.commit('customer/DEFAULT_APP_LOGS')
                } else {
                    this.$store.dispatch('customer/getAppLogs', { page : `?level=${value}` })
                    .then(_ => {
                        this.vloading = false
                    }).catch(err => {
                        this.vloading = false
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.changeAppName()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    })
                }
            },
            dateFilter (e) {
                this.$store.commit('customer/FILTER_SYSTEM_DATE', e)
            },
            dateFilterAudit (e) {
                this.$store.commit('customer/FILTER_AUDIT_DATE', e)
            },
            getSystemAppLogs (userID) {
                const URL = userID ? this.$services.endpoints.GET_SYSTEM_LOGS + `${userID}/` : this.$services.endpoints.GET_SYSTEM_LOGS
                this.$store.dispatch('customer/getSystemAppLogs', URL)
                .then(_ => {
                    this.vloading = false
                }).catch((_) => {
                    this.vloading = false
                })
            },
            getAppLogs () {
                this.$store.dispatch('customer/getAppLogs', {})
                .then(_ => {
                    this.vloading = false
                }).catch(err => {
                    this.vloading = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getAppLogs()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  background-repeat: no-repeat;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 1px;
  border-radius: 0.25rem;
  padding-top: 0.3rem !important;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 0.9rem !important;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}
.sticky-table{
    position: -webkit-sticky;
    position: sticky;
    top: 61px;
    background-color: #fff;
    // background-color: #e8eaec;
    height: 50px;
    padding: 7px;
}
.card-body{
    padding: 0 0.9rem !important;
}
.app-main .app-main__inner{
    padding-top: 20px !important;
}
.body-tabs-shadow .body-tabs-animated{
    padding: unset !important;
}
.tab-btn.active{
    box-shadow: unset !important;
    border: 1px solid $theme-secondary-color !important;
    font-weight: 800;
    color: $theme-secondary-color !important;
    border-radius: 5px;
}
.tab-btn{
    color: $theme-secondary-light;
}
.remove-bg{
    background: unset !important;
    background-color: unset !important;
}
</style>