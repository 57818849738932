<template>
    <div class="app-container body-tabs-shadow">
        <div class="container">
            <div class="">
                <div class="d-flex">
                    <div class="text-center mt-4 float-left">
                        <!-- <img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/header_logo.png" alt=""> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="md:ml-32  md:w-1/2">
                            <!-- <img class="img-fluid d-none d-lg-block" src="./../../assets/images/illus/regis.svg" alt="authentication"> -->
                            <!-- <h3 class="text-gray-800 whitespace-nowrap mt-10 md:-mt-0  font-semibold text-2xl">{{ $t('createNewAccount') }}</h3> -->
                            <!-- <div class="w-full whitespace-pre-wrap"> </div> -->
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="login-border-section mt-10 md:mt-5">
                            <ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
                            <form class="mb-5" @submit.prevent="handleSubmit(processRegister)">
                                <!-- <div class="form-group row mb-8">
                                    <label for="exampleEmail" class="col-sm-4 override-pb override-pt col-form-label label-context -mt-3"></label>
                                    <div>
                                        <div class="font-semibold text-2xl link-color whitespace-nowrap">{{ $t('createNew') }}</div><br>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base">{{ $t('username') }}</label>
                                    <div>
                                        <ValidationProvider name="username" rules="required|minmax:4,15" v-slot="{ errors }">
                                            <input name="username" v-model="username" type="text" class="form-control font-light override-form-field-l" required>
                                            <small class="text-red-500">{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                
                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base">{{ $t('companyName') }}</label>
                                    <div>
                                        <ValidationProvider name="companyName" rules="required|minmax:4,15" v-slot="{ errors }">
                                            <input name="company" v-model="company_name" type="text" class="form-control font-light override-form-field-l" required>
                                            <small class="text-red-500">{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base">{{ $t('companyNumber') }}</label>
                                    <div>
                                        <ValidationProvider name="companyNumber" rules="required|validcom" v-slot="{ errors }">
                                            <input v-model="company_number" type="text" class="form-control font-light override-form-field-l" required>
                                            <small class="text-red-500">{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base">{{ $t('firstName') }}</label>
                                    <div>
                                        <ValidationProvider name="firstName" rules="required|max:50" v-slot="{ errors }">
                                            <input name="firstname" v-model="firstname" type="text" class="form-control font-light override-form-field-l" required>
                                            <small class="text-red-500">{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base">{{ $t('lastName') }}</label>
                                    <div>
                                        <ValidationProvider name="lastName" rules="required|max:50" v-slot="{ errors }">
                                            <input name="lastname" v-model="lastname" type="text" class="form-control font-light override-form-field-l" required>
                                            <small class="text-red-500">{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base">{{ $t('email') }}</label>
                                    <div>
                                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                                            <input name="email" v-model="email" type="email" class="form-control font-light override-form-field-l" required>
                                            <small class="text-red-500">{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base">{{ $t('password') }}</label>
                                    <div>
                                        <ValidationProvider name="password" rules="required|minmax:7,15" v-slot="{ errors }">
                                            <input v-model="password" name="password" type="password" class="form-control font-light override-form-field-l" required>
                                            <small class="text-red-500">{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label class="override-pb override-pt col-form-label label-context"></label> -->
                                    <div class="mt-4">
                                        <!-- <input v-model="confirmPassword" name="password" type="password" placeholder="Confirm Password" class="form-control override-form-field-l" required> -->
                                        <button class="w-full font-semibold shadow btn btn-custom btn-primary btn-lg btn-wide round-btn custom-btn-color" type="submit" :disabled="invalid || processing">
                                            <span v-if="processing">{{ $t('creatingAccount') }}</span>
                                            <span v-else>{{ $t('signup') }}</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label class="override-pb override-pt col-form-label label-context"></label> -->
                                    <div class="mt-3">
                                        <span class="font-md-login">{{ $t('already') }}? <router-link :to="{name : 'login'}" class="no-deco link-color">{{ $t('login') }}</router-link></span>
                                    </div>
                                </div>
                            </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
                <div class="container font-light text-xs text-center md:bottom-0 md:abslute mb-4">
                    <span>Copyright © <span>{{ current }} Automatisera Mera</span></span>
                    <span> {{ $t('switch') }} 
                        <a @click="changeLocale('en')" href="javascript:void(0);" class="font-bold no-deco no-de-hover link-color" v-if="GET_LOCALE_LANG === 'se'">{{ $t('english') }}</a>
                        <a @click="changeLocale('se')" href="javascript:void(0);" class="font-bold no-deco no-de-hover link-color" v-else>Swedish</a>
                    </span>
                </div>
            </div>
        </div>
        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { ValidationProvider, ValidationObserver, localeChanged } from 'vee-validate'
    import i18n from '@/plugins/i18n'

    export default {
        name : 'register',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert'),
            ValidationProvider,
            ValidationObserver
        },
        data () {
            return {
                current : new Date().getFullYear(),
                baseURL : window.location.origin,
                loaded : false,
                username : '',
                company_name : '',
                company_number : '',
                firstname : '',
                lastname : '',
                email : '',
                password : '',
                confirmPassword : '',
                processing : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                GET_USER_UTIL : 'accountant/GET_USER_UTIL',
                GET_LOCALE_LANG : 'utility/GET_LOCALE_LANG'
            })
        },
        beforeMount () {
            // If user token is set, proceed to dashboard
            if (this.USER_TOKEN) {
                if (this.GET_USER_UTIL) {
                    // window.location.href = this.baseURL + '/accountant' 
                    this.$router.replace({ name : 'accountantdash' })
                } else {
                    // window.location.href = this.baseURL + '/automatisera'    
                    this.$router.replace({ name : 'dashboard' })
                }
            }
        },
        methods : {
            changeLocale (locale) {
                i18n.locale = locale
                this.$store.commit('utility/CHANGE_LOCALE', locale)
                localeChanged()
            },
            processRegister () {
                if (this.username === '' || this.company_name === '' || this.company_number === '' || this.firstname === '' || this.lastname === '' || this.email === '' || this.password === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else if (this.username.length > 50 || this.company_name.length > 50 || this.company_number.length > 50 || this.firstname.length > 50 || this.lastname.length > 50 || this.email.length > 50) {
                    this.$services.helpers.notification(this.$t('fieldValue'), 'error', this)
                } else if (!this.$services.helpers.validateEmailAddress(this.email)) {
                    this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
                } else if (this.password.length < 6) {
                    this.$services.helpers.notification(this.$t('passwordGreater'), 'error', this)
                } else if (this.validateCompanyNumber(this.company_number)) {
                    this.$services.helpers.notification(this.$t('invalidCompany'), 'error', this)
                } else {
                    this.processing = true

                    const payload = {
                        username : this.username,
                        company_name : this.company_name,
                        company_number : this.company_number,
                        email : this.email,
                        password : this.password,
                        first_name : this.firstname,
                        last_name : this.lastname
                    }
                    this.$store.dispatch('auth/registerUser', payload)
                    .then(res => {
                        console.log(res)
                        this.processing = false
                        this.$services.helpers.notification(this.$t('accountCreated'), 'success', this)
                        setTimeout(() => {
                            this.$router.push({ name : 'login' })
                        }, 2000)
                    }).catch((err) => {
                        this.processing = false
                        if (err.response.status === 400) {
                            this.$services.helpers.notification(Object.values(err.response.data.data)[0][0], 'error', this)
                        }
                    })
                }
            },
            validateCompanyNumber (number) {
                if (!number.includes('-')) {
                    return true
                }
                if (number.split('-')[0].length === 6 && number.split('-')[1].length === 4) {
                    return false
                }
                return true
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

#app{
    overflow-y: hidden !important;
}
.login-head-text{
    font-size: 1.2rem !important;
}
.login-head-text-xl{
    font-size: 1.5rem !important;
}
.override-form-field{
    border: 0;
    border-bottom: 1px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: unset !important;
    border-radius: unset !important;
}
// .btn-custom {
//     background-color: #EE4D81 !important;
//     border-color: #EE4D81 !important;
//     color: #FFFFFF;
// }
.override-pt{
    padding-top: 1.2rem !important;
}
.override-pb{
    padding-bottom: unset !important;
}
.overide-width{
    width: 50% !important;
}
.font-md-login{
    font-size: 1rem;
}
.form-group{
    margin-bottom: 0.5rem !important;
}
.form-control{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: 0.2rem !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
</style>